










































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from '@/modules/community/common/components/avatar.vue'

@Observer
@Component({
  components: {
    Avatar,
  },
})
export default class extends Vue {
  @Prop({ default: {} }) repostInfo: any
  @Prop({ default: false }) enableRedirect: any

  redirectDetailPost() {
    if (!this.enableRedirect && !this.repostInfo?.repostedPost?.id) return
    this.$router.push(`/community/post/${this.repostInfo?.repostedPost?.id}`)
  }
}
